
  import { Component } from 'vue-property-decorator'
  import DefaultLayout from '@/layouts/Index.vue'

@Component({
  components: {
    AppBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar.vue'
        ),
    ViewFooter: () =>
      import(
        /* webpackChunkName: "default-footer" */
        './Footer.vue'
        ),
  },
})
  export default class Index extends DefaultLayout {}
